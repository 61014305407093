
const App = () => {
  return (
    <div className="App">
      <header className="App-header">
                      
        <h2 className="title glitch layers" data-text="OMNYX"><span>OMNYX</span></h2> <span className="text-color-primary"></span>
        <p>Your trusted <span className="highlight">partner</span> in cybersecurity solutions</p>
      </header>

      <section id="services" className="App-services">
        <h2>Our Services</h2>
        <div className="service-card">
          <h3>Penetration Testing</h3>
          <p>Identify vulnerabilities before attackers do.</p>
        </div>
        <div className="service-card">
          <h3>Cyber Security Services</h3>
          <p>Comprehensive security solutions tailored for your needs.</p>
        </div>
        <div className="service-card">
          <h3>vCISO Services</h3>
          <p>Expert guidance to bolster your security posture.</p>
        </div>
        <div className="service-card">
          <h3>Incident Response</h3>
          <p>Rapid response to mitigate and manage security incidents.</p>
        </div>
        <div className="service-card">
          <h3>Threat Intelligence</h3>
          <p>Stay ahead with actionable threat insights.</p>
        </div>
      </section>
      <div className="contact-section">
        <a href="mailto:contact@omnyx.co" className="contact-btn">Contact Us</a>
      </div>
      <footer className="App-footer">
        <p>&copy; 2024 Omnyx Global. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;